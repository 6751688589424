import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

export const useOptimisticUpdate = <T extends object>(queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const setOptimisticData = async (updater: (old?: T) => T) => {
    await queryClient.cancelQueries({
      queryKey,
    });
    const previousData = queryClient.getQueryData<T>(queryKey);
    queryClient.setQueryData<T>(queryKey, updater);
    return { previousData };
  };

  return { setOptimisticData };
};
