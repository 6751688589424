import { Icon } from '@iconify/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC, type PropsWithChildren, useState } from 'react';

import Logo from '@/assets/logo-white.svg';
import { isDevelopment } from '@/config/config';
import { useProfile } from '@/hooks/useProfile';
import { queries } from '@/queries';
import { postCvsToJobMatchingOffer } from '@/services/jobmatching';
import { useDashboard } from '@/stores/useDashboardStore';
import { getInitialsFromName } from '@/utils/profile';

import BackButton from '../../BackButton';
import DeepmatchingSidebar from '../../CVGenerator/DeepmatchingSidebar';
import CvUsage from '../../Header/CvUsage';
import UserDropdown from '../../Header/UserDropdown';
import RecruiterSettingsDialog from '../../RecruiterSettings/RecruiterSettingsDialog';
import { DashboardBreadcrumbs } from './Breadcrumbs';

type Props = {};

const Navigation: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [selectedOffer, setSelectedOffer] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const { pathname, push } = useRouter();
  const { data: profileData } = useProfile();

  const links = [
    { name: 'Home', icon: 'tabler:table-filled', href: '/' },
    {
      name: 'Collections',
      icon: 'heroicons-solid:collection',
      href: '/collections',
    },
    {
      name: 'Job Matching',
      icon: 'heroicons-solid:puzzle-piece',
      href: '/job-matching',
    },
  ];

  const {
    setJobMatchingActive,
    jobMatchingActive,
    setSelectedContexts,
    selectedContexts,
  } = useDashboard();
  const { mutate, isLoading } = useMutation({
    mutationKey: ['jobmatching', 'attachCvsToOffer'],
    mutationFn: postCvsToJobMatchingOffer,
    onSuccess: (_res, { offerId }) => {
      push({ pathname: '/job-matching', query: { offerId } });
      queryClient.invalidateQueries({
        queryKey: queries.jobMatching.detail(offerId).queryKey,
      });
      setJobMatchingActive(false);
      setSelectedOffer(null);
      setSelectedContexts([]);
    },
  });
  return (
    <div className="flex max-h-screen flex-1 overflow-hidden">
      <div className="flex shrink-0 flex-col justify-between bg-primary-500 px-4 py-8">
        <div className="flex flex-col">
          <div className="mb-24 flex items-center justify-center">
            <Link
              href={profileData || isDevelopment ? '/' : 'https://mindpal.co/'}
            >
              <Logo />
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            {links.map(({ name, icon, href }) => (
              <Link
                key={name}
                href={href}
                className={classNames(
                  'h-min rounded-full p-3 transition-colors hover:bg-primary-800',
                  {
                    'bg-primary-800': pathname === href,
                  }
                )}
              >
                <Icon icon={icon} className="text-2xl" />
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <RecruiterSettingsDialog
            dialogTriggerProps={{
              asChild: true,
            }}
          >
            <button
              type="button"
              className="h-min rounded-full p-3 transition-colors hover:bg-primary-800"
            >
              <Icon icon="mingcute:settings-6-line" className="text-2xl " />
            </button>
          </RecruiterSettingsDialog>
          <UserDropdown>
            <div className="flex aspect-square w-full items-center justify-center rounded-full bg-neutral-600 text-2xl font-semibold">
              {getInitialsFromName(profileData?.name ?? '')}
            </div>
          </UserDropdown>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-3 overflow-auto px-6 pt-6">
        <div className="flex items-center justify-between overflow-auto ">
          <div className="flex gap-5">
            <DashboardBreadcrumbs />
            <BackButton />
          </div>
          <CvUsage />
        </div>
        {children}
      </div>
      <AnimatePresence>
        {jobMatchingActive && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <DeepmatchingSidebar
              onActiveChange={setJobMatchingActive}
              onOfferSelect={setSelectedOffer}
              onSubmit={() =>
                mutate({
                  cvIds: selectedContexts.map(
                    ({ id: candidateId }) => candidateId
                  ),
                  offerId: selectedOffer!,
                })
              }
              selectedContexts={selectedContexts.map(
                ({ id: candidateId }) => candidateId
              )}
              selectedOffer={selectedOffer}
              isLoading={isLoading}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navigation;
