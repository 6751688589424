import { yupResolver } from '@hookform/resolvers/yup';
import type { DialogTriggerProps } from '@radix-ui/react-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, type PropsWithChildren, useState } from 'react';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import useForm from '@/hooks/useForm';
import { queries } from '@/queries';
import type { OfferType } from '@/services/jobmatching';
import { postJobMatchingOffer } from '@/services/jobmatching';

import Button from '../Button';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  onSuccess?: (
    response: OfferType,
    variables: {
      offer: string;
    },
    context: unknown
  ) => void;
};
const AddJobmatchingOfferDialog: FC<PropsWithChildren<Props>> = ({
  children,
  dialogTriggerProps,
  onSuccess,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) });
  const { mutate: createOffer, isLoading: isCreateOfferLoading } = useMutation({
    mutationKey: ['jobmatching', 'createOffer'],
    mutationFn: postJobMatchingOffer,
    onSuccess: (response, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: queries.jobMatching.list._def,
      });
      onSuccess?.(response, variables, context);
      setDialogOpen(false);
    },
  });

  return (
    <Dialog
      onOpenChange={(open) => {
        reset();
        setDialogOpen(open);
      }}
      open={dialogOpen}
    >
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>Add Job Matching Offer</DialogHeader>
        <form
          className="flex flex-col"
          onSubmit={handleSubmit((data) =>
            createOffer({
              offer: data.offer,
              applications: [],
              externalCandidates: [],
            })
          )}
        >
          <MultiLineInput
            label="Offer"
            labelClassName="text-neutral-800"
            variant="transparent"
            characterLimit={10240}
            {...register('offer')}
            error={errors.offer?.message}
          />
          <Button
            type="submit"
            variant="tertiary"
            isLoading={isCreateOfferLoading}
          >
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddJobmatchingOfferDialog;

const schema = object({
  offer: string().required('This field is required!'),
}).defined();
