export const dateFormatter = ({
  date,
  format = { month: 'short', year: 'numeric' },
  locales,
}: {
  date: Date;
  format?: Intl.DateTimeFormatOptions;
  locales?: string | string[];
}) => {
  return Intl.DateTimeFormat(locales, format).format(date);
};
