import {
  type DialogContentProps,
  type DialogProps,
  type DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { useBlobFromUrl } from '@/hooks/useBlobFromUrl';
import { queries } from '@/queries';
import type {
  GetFromAPIRequestTemplate,
  SendToAPIRequestTemplate,
} from '@/services/settings';
import { patchTemplateRequest } from '@/services/settings';

import LayoutTemplateRequest from './LayoutTemplateRequest';
import StepNumberAndName from './StepNumberAndName';
import SuccessRequestSend from './SuccessRequestSend';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
  data: GetFromAPIRequestTemplate;
};

const EditTemplateRequestDialog: FC<PropsWithChildren<Props>> = ({
  children,
  dialogTriggerProps,
  dialogContentProps,
  dialogProps,
  data,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [templateRequestFormData, setTemplateRequestFormData] =
    useState<SendToAPIRequestTemplate>({
      ...data,
      templateFiles: [],
      customFontFile: null,
      logoFile: null,
    });

  const queryClient = useQueryClient();

  const { mutate, isSuccess } = useMutation({
    mutationFn: patchTemplateRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(queries.settings.templateRequest._ctx.list);
    },
  });

  const handleSetTemplateRequestFormData = (
    values: Partial<SendToAPIRequestTemplate>
  ) => {
    if (step < 3) {
      setTemplateRequestFormData((prev) => ({ ...prev, ...values }));
      setStep((prev) => prev + 1);
    } else {
      mutate({ id: data.id, data: { ...templateRequestFormData, ...values } });
    }
  };

  const handleOnPrevStep = (values?: Partial<SendToAPIRequestTemplate>) => {
    if (values) {
      setTemplateRequestFormData((prev) => ({ ...prev, ...values }));
    }
    setStep((prev) => prev - 1);
  };

  const { data: templateFilesFromUrl, isLoading: isLoadingTemplateFiles } =
    useBlobFromUrl(data.templateFiles);
  const { data: logoFileFromUrl, isLoading: isLoadingLogoFile } =
    useBlobFromUrl(data.logoFile ?? undefined);
  const { data: customFontFileFromUrl, isLoading: isLoadingCustomFontFile } =
    useBlobFromUrl(data.customFontFile ?? undefined);

  const stepsDefaultValues: SendToAPIRequestTemplate = {
    ...templateRequestFormData,
    templateFiles:
      templateRequestFormData?.templateFiles.length > 0
        ? templateRequestFormData.templateFiles
        : templateFilesFromUrl ?? [],
    logoFile: templateRequestFormData.logoFile
      ? templateRequestFormData.logoFile
      : logoFileFromUrl ?? null,
    customFontFile: templateRequestFormData.customFontFile
      ? templateRequestFormData.customFontFile
      : customFontFileFromUrl ?? null,
  };

  const isLoadingBlobsFromUrl = {
    isLoadingCustomFontFile,
    isLoadingLogoFile,
    isLoadingTemplateFiles,
  };

  return (
    <Dialog
      {...dialogProps}
      open={isDialogOpen}
      onOpenChange={() => {
        setIsDialogOpen((prev) => !prev);
        setStep(1);
      }}
    >
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent
        className="flex h-5/6 w-full max-w-3xl flex-col items-center p-16"
        {...dialogContentProps}
      >
        {!isSuccess && (
          <>
            <StepNumberAndName
              currentIndex={step}
              setStep={setStep}
              steps={['Files', 'Style', 'Contact']}
            />
            <LayoutTemplateRequest
              isLoadingBlobsFromUrl={isLoadingBlobsFromUrl}
              onChangeTemplateRequestData={handleSetTemplateRequestFormData}
              step={step}
              stepsDefaultValues={stepsDefaultValues}
              onPrevStep={handleOnPrevStep}
            />
          </>
        )}
        {isSuccess && <SuccessRequestSend />}
      </DialogContent>
    </Dialog>
  );
};

export default EditTemplateRequestDialog;
