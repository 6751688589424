import classNames from 'classnames';
import type { Dispatch, SetStateAction } from 'react';
import { Fragment } from 'react';

import Button from '@/components/Button';
import Text from '@/components/Text';

const StepNumberAndName = ({
  currentIndex,
  steps,
  setStep,
}: {
  currentIndex: number;
  steps: string[];
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div className="mb-4 flex w-full max-w-sm flex-row">
      {steps.map((step, index) => (
        <Fragment key={step}>
          {index !== 0 && (
            <div className="flex h-11 w-full items-center justify-center">
              <div className="h-1 w-full border-b-2 border-primary-100" />
            </div>
          )}
          <div className="flex w-full flex-col items-center justify-start gap-2">
            <div
              className={classNames(
                'flex aspect-square h-11 items-center justify-center rounded-full bg-primary-600',
                {
                  'opacity-30': currentIndex < index + 1,
                }
              )}
            >
              <Button
                disabled={currentIndex < index + 1}
                onClick={() => setStep(index + 1)}
                variant="unstyled"
                size="sm"
                className="disabled:!text-neutral-100 disabled:!opacity-100"
              >
                <Text className="font-semibold" variant="h4">
                  {index + 1}
                </Text>
              </Button>
            </div>
            <div>
              <Text className={classNames('text-neutral-1000')}>{step}</Text>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default StepNumberAndName;
