import { faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import type { FC, MouseEvent } from 'react';

import { useDeleteJobOffer } from '@/hooks/useDeleteOffer';
import { queries } from '@/queries';

import Button from '../Button';
import AddJobmatchingOfferDialog from '../JobMatching/AddJobmatchingOfferDialog';
import Text from '../Text';
import { DialogHeader } from '../ui/dialog';

type Props = {
  onActiveChange: (active: boolean) => void;
  onOfferSelect: (offer: number) => void;
  selectedOffer: number | null;
  selectedContexts: number[];
  onSubmit: () => void;
  isLoading?: boolean;
};
const DeepmatchingSidebar: FC<Props> = ({
  onActiveChange,
  onOfferSelect,
  selectedOffer,
  selectedContexts,
  onSubmit,
  isLoading,
}) => {
  const { data: offers } = useQuery(
    queries.jobMatching.list({
      pageIndex: 0,
      pageSize: 999,
    })
  );
  const { mutate } = useDeleteJobOffer();
  const handleDeleteOffer = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    id: number
  ) => {
    mutate({ offerId: id });
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div className="flex h-screen max-h-screen w-full max-w-sm flex-col justify-between overflow-hidden bg-neutral-100 transition-all ease-in-out">
      <div className="scroll-transparent flex h-full flex-col overflow-auto">
        <div className="flex items-start justify-between px-5 pt-5">
          <DialogHeader>Job Matching</DialogHeader>
          <Button
            prefixIcon={faXmark}
            onClick={() => onActiveChange(false)}
            variant="unstyled"
            size={null}
            className="text-neutral-800"
          />
        </div>
        <Text className="mb-3 px-5 text-xs text-neutral-800">
          Select offer and candidates to start job matching
        </Text>
        <div className="scroll-transparent flex flex-1 flex-col gap-3 overflow-auto">
          <AddJobmatchingOfferDialog dialogTriggerProps={{ asChild: true }}>
            <Button className="mx-5" variant="quaternary" prefixIcon={faPlus}>
              Add offer
            </Button>
          </AddJobmatchingOfferDialog>
          {offers?.results.map((offer) => (
            <button
              type="button"
              key={offer.id}
              className={classNames(
                'mx-5 rounded-lg border-2 transition-colors border-transparent bg-neutral-100 p-3 shadow-review-card',
                {
                  '!border-primary-500': selectedOffer === offer.id,
                }
              )}
              onClick={() => onOfferSelect(offer.id)}
            >
              <div className="flex w-full flex-row items-center justify-between">
                <Text className="text-sm font-semibold text-neutral-950">
                  {offer.title.slice(0, 250)}
                  {offer.title.length > 250 && '...'}
                </Text>
                <Button
                  prefixIcon={faTrash}
                  variant="unstyled"
                  className="aspect-square h-6 text-neutral-1000 duration-200 hover:bg-neutral-1000/20"
                  size="sm"
                  confirm="Are you sure you want to delete this offer?"
                  onClick={(e) => handleDeleteOffer(e, offer.id)}
                />
              </div>
              <Text className="text-xs text-neutral-800">
                {offer.text.slice(0, 250)}
                {offer.text.length > 250 && '...'}
              </Text>
            </button>
          ))}
        </div>
      </div>
      <div className="flex w-full flex-col gap-1 px-5 pb-5">
        <Button
          onClick={() => onActiveChange(false)}
          variant="secondary"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedOffer || selectedContexts.length <= 0}
          variant="tertiary"
          tooltipContent={
            !selectedOffer || selectedContexts.length <= 0
              ? 'Please select an offer and at least one resume'
              : undefined
          }
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default DeepmatchingSidebar;
