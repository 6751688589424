import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useIsMutating } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react';

import { useOnboarding } from '@/hooks/Onboarding/useOnboarding';
import { useSettingsOnboarding } from '@/hooks/Onboarding/useSettingsOnboarding';
import { useSettingsQuery } from '@/hooks/useSettingsQuery';
import { queries } from '@/queries';
import { getStringParam } from '@/utils/queryParams';

import Joyride from '../Joyride';
import LoadingPlaceholder from '../LoadingPlaceholder';
import SavingAnimation from '../SavingAnimation';
import Text from '../Text';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';
import { ToggleGroup, ToggleItem } from '../ui/toggle-group';
import SumTimeExperienceFormat from './ SumTimeExperienceFormat';
import Date from './Date';
import Language from './Language';
import Template from './Template';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
  className?: string;
};
type Settings = {
  name: string;
  render: ReactNode;
};
const RecruiterSettingsDialog: FC<PropsWithChildren<Props>> = ({
  className,
  dialogTriggerProps,
  dialogContentProps,
  dialogProps,
  children,
}) => {
  const { replace, query } = useRouter();
  const isMutating = useIsMutating({
    mutationKey: queries.settings.mine.queryKey,
  });
  const { data, isLoading } = useSettingsQuery();
  const { mutate } = useOnboarding();
  const [{ steps, run }, setState] = useSettingsOnboarding();
  const settings: Settings[] = [
    {
      name: 'Date',
      render: <Date data={data} key="Date" />,
    },
    {
      name: 'Work experience',
      render: <SumTimeExperienceFormat data={data} />,
    },
    {
      name: 'Language',
      render: <Language data={data} />,
    },
    {
      name: 'Template',
      render: <Template data={data} />,
    },
  ];

  const currentSetting = settings.find(
    ({ name }) => name === getStringParam(query.setting)
  );
  const handleOnChangeSetting = (newQuery: string | undefined) => {
    return replace({
      query: { ...query, setting: newQuery },
    });
  };

  const isRecruiterSettingsDialogOpen =
    !!query.setting &&
    settings.some(({ name }) => name === (getStringParam(query.setting) ?? ''));

  useEffect(() => {
    if (run && isRecruiterSettingsDialogOpen)
      setTimeout(() => {
        document.body.style.pointerEvents = '';
      }, 0);
  }, [run, isRecruiterSettingsDialogOpen]);
  return (
    <Dialog
      {...dialogProps}
      open={isRecruiterSettingsDialogOpen}
      onOpenChange={(querySetting) =>
        !querySetting
          ? handleOnChangeSetting(undefined)
          : handleOnChangeSetting(settings[0].name)
      }
    >
      <DialogTrigger asChild {...dialogTriggerProps} className={className}>
        {children}
      </DialogTrigger>
      <DialogContent
        data-joyride="settings-dialog"
        {...dialogContentProps}
        className="h-3/4 w-full max-w-6xl overflow-y-scroll"
      >
        {isLoading && <LoadingPlaceholder />}
        {data && (
          <AnimatePresence>
            <div className="flex flex-col gap-4">
              <Joyride
                onFinished={() => {
                  setState((prev) => ({ ...prev, run: false }));
                  mutate('SETTINGS');
                }}
                disableScrollParentFix
                steps={steps}
                run={run}
              />
              <DialogHeader className="border-b border-neutral-400 pb-5 text-neutral-1000">
                <Text className="font-semibold" variant="h4">
                  Settings
                </Text>
              </DialogHeader>
              <ToggleGroup
                className="mb-2"
                type="single"
                value={getStringParam(query.setting)}
                variant="secondary"
                onValueChange={handleOnChangeSetting}
              >
                {settings.map(({ name }) => (
                  <ToggleItem key={name} value={name}>
                    {name}
                  </ToggleItem>
                ))}
              </ToggleGroup>
              {currentSetting && currentSetting.render}
            </div>
            <SavingAnimation
              isDark
              key="saving-animation"
              animationTrigger={!!isMutating}
              successSavedMessage="Settings saved"
            />
          </AnimatePresence>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RecruiterSettingsDialog;
