import { useMutation } from '@tanstack/react-query';

import queryClient from '@/config/queryClient';
import { queries } from '@/queries';
import type { OffersListType } from '@/services/jobmatching';
import { deleteJobMatchingOffer } from '@/services/jobmatching';

import { useOptimisticUpdate } from './useOptimisticUpdate';

export const useDeleteJobOffer = () => {
  const { setOptimisticData } = useOptimisticUpdate<OffersListType>(
    queries.jobMatching.list._def
  );

  const handleOptimisticUpdate = async (variables: { offerId: number }) =>
    setOptimisticData((oldData) => ({
      ...oldData!,
      results: (oldData?.results ?? []).filter(
        (offer) => offer.id !== variables.offerId
      ),
    }));

  return useMutation({
    mutationFn: deleteJobMatchingOffer,
    mutationKey: ['jobMatching', 'deleteOffer'],
    onMutate: handleOptimisticUpdate,
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(
        queries.jobMatching.list._def,
        context?.previousData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queries.jobMatching.list._def,
      });
    },
  });
};
