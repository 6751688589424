import {
  type DialogContentProps,
  type DialogProps,
  type DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { queries } from '@/queries';
import type { SendToAPIRequestTemplate } from '@/services/settings';
import { postTemplateRequest } from '@/services/settings';

import LayoutTemplateRequest from './LayoutTemplateRequest';
import StepNumberAndName from './StepNumberAndName';
import { posibleSections } from './Steps/CVTemplateAndSections';
import SuccessRequestSend from './SuccessRequestSend';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
};

export const defaultTemplateValues = {
  templateFiles: [],
  sections: posibleSections,
  extraSections: '',
  logoFile: null,
  customFontFile: null,
  fontName: '',
  title: '',
  header1: '',
  header2: '',
  text: '',
  caption: '',
  companyName: '',
  email: '',
  phone: '',
  notes: '',
};
const AddTemplateRequestDialog: FC<PropsWithChildren<Props>> = ({
  children,
  dialogTriggerProps,
  dialogContentProps,
  dialogProps,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [templateRequestFormData, setTemplateRequestFormData] =
    useState<SendToAPIRequestTemplate>(defaultTemplateValues);

  const queryClient = useQueryClient();

  const { mutate, isSuccess, reset } = useMutation({
    mutationFn: postTemplateRequest,
    mutationKey: ['templateRequest, sendNewRequest'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.settings.templateRequest._ctx.list);
    },
  });

  const handleSetTemplateRequestFormData = (
    values: Partial<SendToAPIRequestTemplate>
  ) => {
    if (step < 3) {
      setTemplateRequestFormData((prev) => ({ ...prev, ...values }));
      setStep((prev) => prev + 1);
    } else {
      mutate({ ...templateRequestFormData, ...values });
    }
  };

  const handleOnPrevStep = (values?: Partial<SendToAPIRequestTemplate>) => {
    if (values) {
      setTemplateRequestFormData((prev) => ({ ...prev, ...values }));
    }
    setStep((prev) => prev - 1);
  };

  return (
    <Dialog
      {...dialogProps}
      open={isDialogOpen}
      onOpenChange={() => {
        if (isDialogOpen) {
          setTemplateRequestFormData(defaultTemplateValues);
        }
        setIsDialogOpen((prev) => !prev);
        setStep(1);
        reset();
      }}
    >
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent
        className="flex h-5/6 w-full max-w-3xl flex-col items-center p-16"
        {...dialogContentProps}
      >
        {!isSuccess && (
          <>
            <StepNumberAndName
              currentIndex={step}
              setStep={setStep}
              steps={['Files', 'Style', 'Contact']}
            />
            <LayoutTemplateRequest
              onChangeTemplateRequestData={handleSetTemplateRequestFormData}
              step={step}
              stepsDefaultValues={templateRequestFormData}
              onPrevStep={handleOnPrevStep}
            />
          </>
        )}
        {isSuccess && <SuccessRequestSend />}
      </DialogContent>
    </Dialog>
  );
};

export default AddTemplateRequestDialog;
