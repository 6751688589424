import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  PropsWithChildren,
} from 'react';
import { forwardRef, useState } from 'react';
import type { FieldError } from 'react-hook-form';

import Button from '@/components/Button';
import ErrorAccordion from '@/components/ErrorAccordion';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';

type CheckboxTextStyleProps = VariantProps<typeof checkboxTextStyle>;
const checkboxTextStyle = cva('', {
  variants: {
    textSize: {
      caption: 'text-caption',
      xs: 'text-xs',
      sm: 'text-body-s',
      md: 'text-body-m',
      lg: 'text-body-l',
    },
  },
  defaultVariants: {
    textSize: 'md',
  },
});

type CheckboxStyleProps = VariantProps<typeof checkboxStyles>;

const checkboxStyles = cva('transition-colors', {
  variants: {
    variant: {
      'white-background':
        'border-primary-800 bg-white peer-checked:bg-primary-800 peer-disabled:bg-neutral-500',
      transparent:
        'border-neutral-100 bg-transparent peer-checked:border-primary-800 peer-checked:bg-primary-800 peer-disabled:bg-neutral-500',
    },
  },
  defaultVariants: {
    variant: 'white-background',
  },
});

interface CheckboxVariantProps
  extends CheckboxStyleProps,
    CheckboxTextStyleProps {}

type Props = {
  label?: string;
  required?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  checkboxClassName?: string;
} & CheckboxVariantProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  ErrorDetailsProps<FieldError>;

const Checkbox = (
  {
    label = '',
    error,
    required,
    textSize,
    className,
    children,
    labelProps,
    canHaveErrorMessage = true,
    checkboxClassName,
    variant,
    ...props
  }: PropsWithChildren<Props>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const maxLabelLength = 250;
  const [showMore, setShowMore] = useState(false);
  const showExpandButton = label.length > maxLabelLength;
  return (
    <div
      key={props.name + label}
      className={classNames('relative flex flex-col')}
    >
      <label
        htmlFor={props.name + label}
        className={classNames(
          'flex flex-1 cursor-pointer py-4 text-neutral-300',
          { '!cursor-not-allowed': props.disabled },
          className
        )}
        {...labelProps}
      >
        <div className="relative inline-flex h-5 items-center justify-center rounded-[0.25rem]">
          <input
            className="peer absolute h-full w-full opacity-0 hover:cursor-pointer "
            type="checkbox"
            id={props.name + label}
            ref={ref}
            {...props}
          />
          <span
            className={classNames(
              'flex h-5 w-5 aspect-square items-center justify-center rounded-[0.25rem] border peer-checked:[&>svg]:block [&>svg]:hidden peer-disabled:opacity-20 peer-disabled:[&>svg]:!hidden transition-colors',
              {
                '!border-error': error,
                'mr-4': label || children,
              },
              checkboxClassName,
              checkboxStyles({ variant })
            )}
          >
            <FontAwesomeIcon icon={faCheck} color="white" />
          </span>
        </div>
        <div
          className={classNames('', {
            "after:px-1 after:text-red-500 after:content-['*'] after:absolute":
              required,
          })}
        >
          {label && (
            <span className={classNames(checkboxTextStyle({ textSize }))}>
              {label.slice(0, showMore === true ? undefined : maxLabelLength)}
              {!showMore && showExpandButton && '...'}
            </span>
          )}
          {children}
        </div>
      </label>
      {showExpandButton && (
        <Button
          className="text-body-s self-end"
          onClick={() => setShowMore((prev) => !prev)}
          size={null}
          variant="unstyled"
        >
          Show {showMore ? 'less' : 'more'}
        </Button>
      )}
      {canHaveErrorMessage && <ErrorAccordion error={error?.message} />}
    </div>
  );
};

export default forwardRef(Checkbox);
