/* eslint-disable react/no-array-index-key */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { useExternalCandidate } from '@/hooks/useExternalCandidate';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';
import { getNumberParams } from '@/utils/queryParams';

export const DashboardBreadcrumbs = () => {
  const router = useRouter();
  const splitPath = router.pathname.split('/').filter((part) => part !== '');
  const { id } = router.query;
  const parsedCandidateId = getNumberParams(id);
  const { data } = useExternalCandidate(parsedCandidateId ?? 0, {
    enabled: !!parsedCandidateId,
  });
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {splitPath.map((part, index, arr) => (
          <Fragment key={index}>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  href={{
                    pathname: `/${splitPath.slice(0, index + 1).join('/')}`,
                    query: { id: part === '[id]' ? id : undefined },
                  }}
                >
                  {part === '[id]'
                    ? data?.name
                    : part
                        .split('-')
                        .map((partSegment) =>
                          capitalizeFirstLetter(partSegment)
                        )
                        .join(' ')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {index !== arr.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
