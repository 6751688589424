import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularLoadingIndicator } from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import type { Segment } from 'framer-motion';
import { animate, motion } from 'framer-motion';
import type { FC } from 'react';
import { useEffect } from 'react';

import Text from './Text';

type Props = {
  animationTrigger: boolean;
  isDark?: boolean;
  successSavedMessage: string;
};
const SavingAnimation: FC<Props> = ({
  animationTrigger,
  isDark,
  successSavedMessage,
}) => {
  useEffect(() => {
    const processingElement = document.getElementById('processing');
    const saveElement = document.getElementById('save');
    if (!processingElement || !saveElement) return;

    if (animationTrigger) {
      const sequences: Segment[] = [
        [
          processingElement,
          {
            display: 'flex',
            x: ['100%', '0%'],
            opacity: [0, 1],
          },
          { type: 'spring' },
        ],
      ];
      animate(sequences);
      return;
    }

    const sequence: Segment[] = [
      [
        processingElement,
        {
          x: ['0%', '100%'],
          opacity: [1, 0],
        },
        { delay: 1, type: 'spring' },
      ],
      [
        saveElement,
        {
          display: 'flex',
          x: ['100%', '0%'],
          opacity: [0, 1],
        },
        { type: 'spring' },
      ],
      [
        saveElement,
        {
          x: ['0%', '100%'],
          opacity: [1, 0],
        },
        { delay: 0.5, type: 'spring' },
      ],
    ];
    const animation = animate(sequence);
    return () => {
      animation.complete();
    };
  }, [animationTrigger]);

  return (
    <div
      key="status-saving-animation"
      className="relative flex min-h-[2rem] w-full items-center justify-end overflow-hidden"
    >
      <motion.div
        initial={{ display: 'none' }}
        animate={{ transitionEnd: { display: 'none' } }}
        id="save"
        key="save-motion-saveanimation"
        className="absolute translate-x-full items-center gap-2 self-end"
      >
        <FontAwesomeIcon
          className={classNames('', {
            'text-neutral-800': isDark,
          })}
          icon={faCheck}
        />
        <Text
          className={classNames('', {
            'text-neutral-800': isDark,
          })}
        >
          {successSavedMessage}
        </Text>
      </motion.div>
      <motion.div
        initial={{ display: 'none' }}
        id="processing"
        key="processing-motion-saveanimation"
        className="absolute flex min-h-max items-center gap-2 self-end overflow-hidden"
      >
        <CircularLoadingIndicator size="sm" />
        <Text
          className={classNames('', {
            'text-neutral-800': isDark,
          })}
        >
          Processing...
        </Text>
      </motion.div>
    </div>
  );
};

export default SavingAnimation;
